import React from "react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import * as styles from "./Hero.module.scss";
import { APP_LOGIN_URL, SUPPORT_EMAIL, SUPPORT_NUMBER } from "data/config";
import { Offline, Available } from "assets/media/svgs";

import appData from "../../../../app.json";

interface HeroProps {
  backgroundImage: any;
  useOptimizedBackground?: boolean;
}

const Hero: React.FC<HeroProps> = ({
  backgroundImage,
  useOptimizedBackground,
}) => {
  const image = getImage(backgroundImage);
  const available =
    new Date().getHours() + 1 > appData.CSHours.am &&
    new Date().getHours() < appData.CSHours.pm + 12;

  return (
    <section className={styles.Hero}>
      <div className={styles.Hero_container}>
        <header className={styles.Hero_header}>
          <h1 className={styles.Hero_header_text}>
            Hello. <br />
            <br /> What can we help you with today?
          </h1>
          <p className={styles.Hero_header_body}>
            Let's get you started with the trusted support you need for your
            business. Contact our support team or read through our FAQs.
          </p>
          <div className={styles.Hero_infoPanel}>
            <div className={styles.Hero_infoPanel_links}>
              <a href={`tel:${SUPPORT_NUMBER}`}>T: {SUPPORT_NUMBER}</a>
              <a href={`mailto:${SUPPORT_EMAIL}`}>E: {SUPPORT_EMAIL}</a>
              <a
                target="_blank"
                rel="noopener noreferrer"
                href={appData.social.twitter}
              >
                @BrassHelp
              </a>
              <a href={APP_LOGIN_URL} target="_blank" rel="noopener noreferrer">
                Login for expedited support
              </a>
            </div>
            <div className={styles.Hero_infoPanel_status}>
              <p>
                Available between {appData.CSHours.am}AM to {appData.CSHours.pm}
                PM WAT
              </p>
              <div>
                {available ? (
                  <>
                    <Available /> <p>Available now!</p>
                  </>
                ) : (
                  <>
                    <Offline /> <p>Unavailable.</p>
                  </>
                )}
              </div>
            </div>
          </div>
        </header>
        {backgroundImage && (
          <div className={styles.Hero_backgroundImage}>
            {image && useOptimizedBackground && (
              <GatsbyImage image={image} alt="hero" as="span" />
            )}
            {!useOptimizedBackground && (
              <img src={backgroundImage} alt="hero" />
            )}
          </div>
        )}
      </div>
    </section>
  );
};

export default Hero;
