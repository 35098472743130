import React from "react";
import { Tab, TabList, TabPanel, Tabs } from "react-tabs";
import { Accordion, AccordionItem, Select } from "brass-ui-kit";

import * as styles from "./Faq.module.scss";

interface FaqProps {
  data: {
    title: string;
    visible: boolean;
    content: { question: string; answer: string }[];
  }[];
}

const Faq: React.FC<FaqProps> = ({ data }) => {
  const [tabIndex, setTabIndex] = React.useState(0);

  return (
    <section className={styles.Faq}>
      <div className={styles.Faq_container}>
        <div className={styles.Faq_header}>
          <h1 className={styles.Faq_header_title}>
            Frequently Asked Questions
          </h1>
          <p>We answered questions so you don’t have to ask them.</p>
        </div>

        <Tabs
          selectedIndex={tabIndex}
          className={styles.Faq_list}
          onSelect={(index: number) => setTabIndex(index)}
          selectedTabClassName={styles.Faq_tabList_item_selected}
          selectedTabPanelClassName={styles.Faq_tabPanel_selected}
        >
          <TabList className={styles.Faq_tabList}>
            {data.map(({ title, visible }, index: number) => {
              return (
                visible && (
                  <Tab
                    key={index}
                    className={styles.Faq_tabList_item}
                    disabledClassName={styles.Faq_tabList_item_disabled}
                  >
                    <h3 className={styles.Faq_tabList_item_title}>{title}</h3>
                  </Tab>
                )
              );
            })}
          </TabList>

          <div
            style={{
              width: "100%",
            }}
            className={styles.Faq_button}
          >
            <Select
              onChange={(item) => setTabIndex(item.index)}
              options={data
                .filter((item) => item.visible)
                .map(({ title }: any, index: number) => ({
                  index,
                  title,
                }))}
              labelField="title"
              valueField="index"
              value={tabIndex}
            />
          </div>

          {data.map(({ content, visible }, index: number) => {
            return (
              visible && (
                <TabPanel key={index} className={styles.Faq_tabPanel}>
                  <Accordion>
                    {content.map(({ question, answer }: any, index: number) => {
                      return (
                        <AccordionItem
                          key={index}
                          header={question}
                          body={answer}
                          className={styles.Faq_tabPanel_item}
                          activeClassName={styles.Faq_tabPanel_item_active}
                        />
                      );
                    })}
                  </Accordion>
                </TabPanel>
              )
            );
          })}
        </Tabs>
      </div>
    </section>
  );
};

export default Faq;
